import React from "react"
import Helmet from "react-helmet"

const RedirectTemplate = ({ pageContext }) => {

  return (
    <Helmet>
      <meta http-equiv="refresh" content={ `0; url=${pageContext.redirectTo}` } />
    </Helmet>
  )
}

export default RedirectTemplate
